<template>
    <component :is="takeOutEleDetailDemandx"></component>
</template>
<script>
import { mapGetters } from "vuex";
import takeOutEleDetailDemandx from './TakeOutEleDetail.vue'
import takeOutEleDetailDemandx2 from './TakeOutEleDetail2.vue'
export default {
    components: {
        takeOutEleDetailDemandx,
        takeOutEleDetailDemandx2,
    },
    computed: {
        ...mapGetters(['mealRules']),
        takeOutEleDetailDemandx: {
            get: function() {
                var componentId = 'takeOutEleDetailDemandx';
                if(this.mealRules.newUI){
                    componentId = componentId + '2'
                }
                return (componentId);  
            }    
        }
    },
}
</script>