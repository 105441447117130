<template>
    <div class="takeOutEleDetail2">
        <Header backType="white" :back="true"></Header>
        <div class="merchantContent">
            <div class="detail_title">订单详情</div>
            <div class="merchantName">{{orderDetail.shopName}}</div>
            <div class="merchantGroup">
                <div class="merchantMoList">
                    <div v-for="(item,index) in order" :key="'detail' + index">
                        <span class="detail-food-name">{{item.food_name}}</span>
                        <span class="merchantMoNum">{{item.qty | count}}</span>
                        <span
                            class="merchantMoPrice"
                        >{{(item.food_free*item.qty).toFixed(2) | price}}</span>
                    </div>
                    <div
                        class="merchantMoAllPrice"
                        v-for="(item, index) in (takeOutDetail.order_extras_obj || [])"
                        :key="index"
                    >
                        {{item.name}}
                        <span
                            class="merchantMoAllPriceNum"
                        >{{(item.price*item.quantity).toFixed(2) | price}}</span>
                    </div>
                    <div v-if="orderDetail.sourceCode == 'XiaoMiShu'" class="merchantMoAllPrice">
                        配送费
                        <span
                            class="merchantMoAllPriceNum"
                        >{{Number(takeOutDetail.shipping_free).toFixed(2)| price}}</span>
                    </div>
                    <div v-if="orderDetail.sourceCode == 'XiaoMiShu'" class="merchantMoAllPrice">
                        餐盒
                        <span
                            class="merchantMoAllPriceNum"
                        >{{Number(takeOutDetail.box_free).toFixed(2)| price}}</span>
                    </div>
                    <div v-if="priceInfo" class="merchantMoAllPrice">
                        其他费用
                        <span
                            class="merchantMoAllPriceNum"
                        >{{Number(priceInfo.order_amount - foodAmount).toFixed(2)| price}}</span>
                    </div>
                    <div v-if="priceInfo" class="merchantMoAllPrice">
                        订单优惠金额
                        <span
                            class="merchantMoAllPriceNum"
                        >{{ Number(priceInfo.order_amount - priceInfo.actual_total_amount).toFixed(2)| price}}</span>
                    </div>
                    <div v-if="priceInfo" class="merchantMoAllPrice">
                        订单总金额
                        <span
                            class="merchantMoAllPriceNum"
                        >{{priceInfo.order_amount | price}}</span>
                    </div>
                    <div v-if="priceInfo" class="merchantMoAllPrice">
                        实付金额
                        <span
                            class="merchantMoAllPriceNum"
                        >{{takeOutDetail.order_total_price | price}}</span>
                    </div>
                    <div class="AllPrice">
                        <span
                            class="detail-shop-phone"
                        >
                            <span class="border" v-if="takeOutDetail.restaurant_tel">
                                <Svgs color="var(--themeColor)" name="icondianhua-"></Svgs>
                            </span>
                            <span
                            @click="ihtml.telCall(takeOutDetail.restaurant_tel)"
                            v-if="takeOutDetail.restaurant_tel" 
                            class="detail-phone-name">联系商家</span>
                        </span>
                        <div class="AllPrice_price"> 
                            <span>
                                总计
                            </span> &emsp;
                            {{takeOutDetail.order_total_price | price}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="merchantGroup">
                <div class="merchantGroupHead">订单信息</div>
                <div class="merchantGroupList">
                    <div>
                        <span class="merchantGroupLabel">订餐人姓名</span> 
                        <span
                            class="merchantGroupTxt"
                        >{{orderDetail.creatorDspName || ''}}</span>
                    </div>
                    <div>
                        <span class="merchantGroupLabel">订餐时间</span> 
                        <span
                            class="merchantSuccess merchantGroupTxt" 
                        >{{(address.orderTime || takeOutDetail.created_on || '').replace(/T/g, " ")}}</span>
                    </div>
                    <div v-if="orderDetail.sourceCode != 'ELE'">
                        <span class="merchantGroupLabel">预计送餐时间</span>
                        <span class="merchantGroupTxt">{{(takeOutDetail.receivePlanTime || '').replace(/T/g, " ")}}</span>
                    </div>
                    <div class="detail_phone" v-if="orderDetail.sourceCode != 'ELE'">
                        <span class="merchantGroupLabel">收餐人</span>
                        <div class="call_phone">
                            <span class="detail_phone_people">{{address.receiverName}}</span>
                            <span
                                class="detail-shop-phone"
                                v-if="address.receiverTel"
                                @click="ihtml.telCall(address.receiverTel)"
                            >
                                <span class="border">
                                    <Svgs color="var(--themeColor)" name="icondianhua-"></Svgs>
                                </span>
                                <span class="detail-phone-name">{{address.receiverTel}}</span>
                            </span>
                        </div>
                    </div>
                    <div v-if="orderDetail.sourceCode != 'ELE'">
                        <span class="merchantGroupLabel">送餐地址</span>
                        <span class="merchantGroupTxt">{{address.receiverAddress}}</span>
                    </div>
                    <div v-if="orderDetail.sourceCode != 'ELE' && event.tenant_name != '会引擎-灵北' && event.tenant_code != 'novartis'">
                        <span class="merchantGroupLabel">实际用餐人数</span>
                        <span class="merchantGroupTxt">{{takeOutDetail.actualQty}}</span>
                    </div>
                    <div>
                        <span class="merchantGroupLabel">预算金额</span>
                        <span class="merchantGroupTxt weight_bold">{{orderDetail.amount | price}}</span>
                    </div>
                    <div class="detail-mark">
                        <span class="merchantGroupLabel">备注</span>
                        <span class="merchantGroupTxt">{{takeOutDetail.remark}}</span>
                    </div>
                    <div v-if="orderDetail.sourceCode != 'ELE'" class="detail-mark">
                        <span class="merchantGroupLabel">发票抬头</span>
                        <span class="merchantGroupTxt">{{takeOutDetail.ext_restaurant_data | invoiceName}}</span>
                    </div>
                    <div v-if="orderDetail.sourceCode != 'ELE'" class="detail-mark">
                        <span class="merchantGroupLabel">发票税号</span>
                        <span class="merchantGroupTxt">{{takeOutDetail.ext_restaurant_data | invoiceNumber}}</span>
                    </div>
                    <div class="detail-phone" v-if="tracking.deliverymanInfo">
                        <span class="merchantGroupLabel">配送骑手</span>
                        <span class="detail-phone-people">{{tracking.deliverymanInfo ? (tracking.deliverymanInfo.name || '无') : '无'}}</span>
                        <span
                            v-if="tracking.deliverymanInfo && tracking.deliverymanInfo.phone"
                            class="detail-shop-phone"
                            @click="ihtml.telCall(tracking.deliverymanInfo.phone)"
                        >
                            <span class="border">
                                <Svgs color="var(--themeColor)" name="icondianhua-"></Svgs>
                            </span>
                            <span class="detail-phone-name">联系骑手</span>
                        </span>
                    </div>
                    <div v-if="tracking.deliverymanInfo">
                        <span class="merchantGroupLabel">配送状态</span>
                        <span class="merchantGroupTxt color_4AC672">
                            {{getSatus(tracking.stateCode)}}
                        </span>
                    </div>
                    <div v-if="[50, 60].includes(takeOutDetail.status)">
                        <span class="merchantGroupLabel">骑手位置</span>
                        <div class="gogps" @click="goGps">
                            <Svgs color="var(--themeColor)" name="iconditu"></Svgs>
                            <p class="color_3875FE">查看骑手位置</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="merchantGroup">
                <div class="merchantGroupHead">订单状态</div>
                <ul class="merchantGroupList">
                    <div>
                        <span class="merchantGroupLabel">订单状态</span>
                        <span class="merchantGroupTxt status">{{statusTxt}}</span>
                    </div>  
                    <div v-if="orderDetail.sourceCode != 'ELE' && takeOutDetail.statusTxt">
                        <span v-if="orderDetail.sourceCode == 'DianPing'" class="merchantGroupLabel">第三方状态</span>
                        <span v-if="orderDetail.sourceCode == 'XiaoMiShu'" class="merchantGroupLabel">小秘书状态</span>
                        <span class="merchantGroupTxt status">{{takeOutDetail.statusTxt}}</span>
                    </div>
                    <div v-if="takeOutDetail.order_no">
                        <span v-if="orderDetail.sourceCode == 'ELE'" class="merchantGroupLabel">饿了么订单号</span>
                        <span v-else-if="orderDetail.sourceCode == 'DianPing'" class="merchantGroupLabel">第三方订单号</span>
                        <span v-if="orderDetail.sourceCode == 'XiaoMiShu'" class="merchantGroupLabel">小秘书订单号</span>
                        <span class="merchantGroupTxt status">
                            {{takeOutDetail.order_no}}
                        </span>
                    </div>
                    <div>
                        <span class="merchantGroupLabel">收货时间</span>
                        <span class="merchantGroupTxt status">
                            {{(takeOutDetail.zh_receiveTime || '').replace(/T/g, " ")}}
                        </span>
                    </div>
                    <div v-if="orderDetail.sourceCode == 'DianPing'">
                        <span class="merchantGroupLabel">第三方详情</span>
                        <span class="merchantGroupTxt status">
                            <span @click="openDdianPing" style="padding:0 30px;border: 1px solid #ccc;border-radius: 5px">查看</span>
                        </span>
                    </div>
                    <div v-if="orderDetail.sourceCode == 'ELE'">
                        <span class="merchantGroupLabel">第三方详情</span>
                        <span class="merchantGroupTxt status">
                            <span @click="openELE" style="padding:0 30px;border: 1px solid #ccc;border-radius: 5px">查看</span>
                        </span>
                    </div>
                </ul>
            </div>
            <div class="merchantBtnRow">
                <div class="merchantGroupHead">发票</div>
                <viewer
                    v-if="invoiceFilesList.length"
                    :images="invoiceFilesList"
                    class="imgContainer"
                >
                    <template v-for="(list) in invoiceFilesList">
                        <div :key="list.url">
                            <p @click="openPdf(list.url)" v-if="list.fileType == '.pdf'">
                                <i-icon style="width:.6rem;height:.6rem" color="var(--themeColor)" name="icon-pdf" />
                            </p>
                            <p  @click="openOfd(list.url)" v-else-if="list.fileType == '.ofd'">
                                <img src="../../assets/img/ofd.png" style="width:.6rem;height:.6rem"/>                             </p>
                            <img v-else :src="list.url" alt/>
                            <p v-if="list.relatedType == 'Invoice_supplement'">补传</p>
                        </div>
                    </template>
                </viewer>
                <div class="nodata" v-else>暂无数据！</div>
            </div>
            <div class="merchantBtnRow" v-if="invoiceReason">
                <div class="merchantGroupHead">原因</div>
                <p class="invoiceReason">
                    {{invoiceReason}}
                </p>
            </div>
            <div class="merchantBtnRow" v-if="itemData && itemData.OwnerConfirm == 2">
                <div class="merchantGroupHead">拒绝原因</div>
                <p class="invoiceReason">
                    {{itemData.OwnerConfirmDescr}}
                </p>
            </div>
        </div>
        <div class="submit-btn">
            <div class="Reject" :class="item.actCode" @click.stop="sub(item)" v-for="(item ,index) in approveBtn" :key="index">
                <span slot="btnContent">{{ item.actName }}</span>
            </div>
        </div>
        <MessageBox ref="submitForm" type="textarea" messageTitle="拒绝原因" pal='请输入原因' :backFun="true" @onOk="submitSettle" messageTxt="请填写拒绝确认订单的原因："></MessageBox>
    </div>
</template>

<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import { UploadPlug } from "smart-filer";
import { SmartStorage } from "smart-core-util";
import mealDdianPing from '@/components/Meal/mealDdianPing.js'
import { Toast } from 'mint-ui';
const uploadInstance = new UploadPlug({
    host: process.env.VUE_APP_GATEWAY
});
export default {
    name: "orderDetail",
    mixins: [mealDdianPing],
    data() {
        return {
            takeOutDetail: {},
            address: {},
            order: [],
            tracking: {},
            statusTxt: "",
            waterFilesList: [],
            invoiceFilesList: [],
            style: {
                width: "0.3rem",
                height: "0.3rem",
                borderRadius: "50%",
                background: "rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            priceInfo: null,
            approveBtn:[],
            itemData:'',
            invoiceReason:null
        };
    },
    computed: {
        ...mapGetters(["orderDetail","mealRules",'event','memsg'])
    },
    mounted() {
        this.getTakeoutDetail();
        this.getWaterFiles();
        this.getInvoiceFiles();
        if(SmartStorage.get('tenant_code') == 'novartis'){
            this.getQueryTaskActions()
        }
    },
    filters: {
        price: value => {
            if (value || value === 0) {
                return "¥" + value;
            }
        },
        count: value => {
            if (value) {
                return "X" + value;
            }
        },
        invoiceNumber: value => {
            if (value) {
                let data = JSON.parse(value)
                return data.invoiceNumber;
            }
        },
        invoiceName: value => {
            if (value) {
                let data = JSON.parse(value)
                return data.invoiceName;
            }
        }
    },
    methods: {
        sub(item){
            this.actionBtn = item
            if(item.actCode == 'Reject'){
                this.$refs.submitForm.openPop(); 
            }else{
                this.approveSuccess(item)
            }
        },
        submitSettle(txt){
            if(!txt.value){
                this.toast(`请填写拒绝原因`);
                return
            }
            let params = {
                taskId: this.orderDetail.taskId,
                actionId: this.actionBtn.actionId,
                TaskData: {
                    Descr: txt.value,
                }
            };
            miceService.PerformTask(params).then(res => {
                if (res.success) {
                    this.toast(`操作成功`);
                    this.$refs.submitForm.openPop(); 
                    this.getTakeoutDetail();
                    this.getQueryTaskActions();
                }
            });
            console.log(txt);
        },
        approveSuccess(item) {
            let params = {
                taskId: this.orderDetail.taskId,
                actionId: item.actionId
            };
            miceService.PerformTask(params).then(res => {
                if (res.success) {
                    this.toast(`操作成功`);
                    this.getTakeoutDetail();
                    this.getQueryTaskActions();
                }
            });
        },
        getQueryTaskActions() {
            let params = {
                processId: this.orderDetail.itemId,
                taskId: this.orderDetail.taskId || '',
                userId: this.memsg.userId
            };
            miceService.QueryTaskActions(params).then(res => {
                if (res.success) {
                    this.approveBtn = res.content;
                }
            });
        },
        openELE(){
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf("micromessenger") != -1;
            if (isWeixin) {            
                this.$router.push(`/common?aliURL=${encodeURIComponent(this.$route.query.aliURL)}&sessionId=${SmartStorage.get("sessionId")}&type=detailEle&placeOrder=true`)
            } else {
                window.location.href = this.$route.query.aliURL
            }
        },
        openDdianPing() {
            const params = {
                itemId: this.orderDetail.itemId
            };
            let _this = this;
            this.openTakeoutDianPing(params, () => {
                miceService.refreshDianPingOrder(params).then(res => {
                    if (res && res.success) {
                        _this.getTakeoutDetail();
                    }
                });
            });
        },
        openPdf(pdfUrl){
            this.iJsBridge.openPdfImg(pdfUrl)
        },
        openOfd(){
            Toast('暂不支持预览Ofd格式文件');
        },
        getTakeoutDetail() {
            let params = {
                itemId: this.orderDetail.itemId
            };
            miceService.getTakeoutDetail(params).then(res => {
                if (res && res.success) {
                    this.takeOutDetail = res.content.order || {};
                    this.priceInfo = res.content.priceInfo || null;
                    this.address = (res.content.order || {}).deliveryInfo || {};
                    this.order = res.content.orderDetail || [];
                    this.tracking = (res.content.tracking || {track: {}}).track || {};
                    this.statusTxt = res.content.statusTxt || "";
                    this.invoiceReason = res.content.invoiceReason || "";
                    let foodAmount = 0;
                    for( let item in this.order){
                        foodAmount = foodAmount + this.order[item].qty * this.order[item].food_free
                    }
                    this.foodAmount = foodAmount
                    try {
                        this.itemData = JSON.parse(res.content.itemData) 
                    } catch (error) {
                        return
                    }
                }
            });
        },
        getWaterFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        processId: this.orderDetail.proposalId,
                        relatedId: this.orderDetail.itemId,
                        relatedType: [
                            "settle",
                            "settle_reback",
                            "SupplierSettle",
                            "settle_supplement"
                        ],
                        catalog: "水单"
                    }
                },
                callback: data => {
                    this.waterFilesList = data.content;
                }
            });
        },
        getInvoiceFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        processId: this.orderDetail.proposalId,
                        itemId: this.orderDetail.itemId,
                        relatedType: ["Invoice", "Invoice_reback",'Invoice_supplement'],
                        catalog: "发票"
                    }
                },
                callback: data => {
                    this.invoiceFilesList = data.content;
                }
            });
        },
        goGps() {
            this.$router.push({ path: `/takeoutGps?addressId=${this.takeOutDetail.address_id}`}) 
        },
        getSatus(code) {
            let result = "";
            switch (code) {
                case 1:
                    result = "运单生成";
                    break;
                case 2:
                    result = "配送商接单";
                    break;
                case 3:
                    result = "分配配送员";
                    break;
                case 4:
                    result = "配送员到店";
                    break;
                case 5:
                    result = "配送员取餐";
                    break;
                case 6:
                    result = "配送成功";
                    break;
                case 7:
                    result = "配送失败";
                    break;
                case 8:
                    result = "配送异常";
                    break;
                default:
                    result = "无";
                    break;
            }
            return result;
        }
    }
};
</script>

<style lang="scss">
@import "../MealOrderDetail/MealOrderDetail";
.takeOutEleDetail2{
    font-family: PingFang SC;
    .merchantGroupHead{
        margin-bottom: .1rem;
    }
    .merchantBtnRow .icon-pdf{
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 0.2rem;
    }

    .invoiceReason{
        word-wrap:break-word;
        text-align: left;
        margin-top: .2rem
    }
    .detail-phone {
        position: relative;
    }

    .detail-shop-phone {
        top: 0px;
        color: #3788f1;
        right: 0px;
        display: flex;
        .border {
            width: 0.2rem !important;
            height: 0.2rem;
            border-radius: 50%;
            text-align: center;
            display: flex;
            align-items: center;
        }
        .detail-phone-name {
            margin-left: 0.05rem;
        }
    }
    .detail-phone-people {
        width: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        text-align: left;
    }
    .detail-food-name {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        text-align: left;
    }
    .detail-rider {
        width: 0.25rem;
    } 
    
.submit-btn{
  position: absolute;
  margin: .25rem 2.5%;
  bottom: 0;
  padding: 0;
  width: 95%;
  display: flex;
  justify-content: space-between;
  div{
    width: 45%;
    height: .45rem;
    line-height:.45rem;

  }
  .Reject{
    background: #fff;
    border: 1px solid #ccc;
    border-radius: .03rem;
  }
  .approved{
    background: var(--themeColor);
    border-radius: .03rem;
    color: #fff
  }
}
}
</style>