<template>
    <div class="takeOutEleDetail">
        <Header backType="blank" :backStyle="style" :svgColor="'#fff'" :back="true"></Header>
        <div
            class="merchantImg"
            :style="{backgroundImage:'url(' + (orderDetail.shopImg || 'https://pic.downk.cc/item/5e81a6f2504f4bcb0418357b.jpg') + ')',backgroundSize: 'cover'}"
        ></div>
        <div class="merchantContent">
            <div class="merchantName">{{orderDetail.shopName}}</div>
            <div class="merchantGroup">
                <div class="merchantGroupHead">订单信息</div>
                <ul class="merchantGroupList">
                    <li>
                        <span class="merchantGroupLabel">订餐时间</span> 
                        <span
                            class="merchantSuccess merchantGroupTxt"
                            >{{(address.orderTime || takeOutDetail.created_on || '').replace(/T/g, " ")}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode != 'ELE'">
                        <span class="merchantGroupLabel">预计送餐时间</span>
                        <span class="merchantGroupTxt">{{(takeOutDetail.receivePlanTime || '').replace(/T/g, " ")}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode != 'ELE'">
                        <span class="merchantGroupLabel">收餐人</span>
                        <span class="merchantGroupTxt">{{address.receiverName}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode != 'ELE'">
                        <span class="merchantGroupLabel">手机号码</span>
                        <span class="merchantGroupTxt">{{address.receiverTel}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode != 'ELE'">
                        <span class="merchantGroupLabel">送餐地址</span>
                        <span class="merchantGroupTxt">{{address.receiverAddress}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode != 'ELE'">
                        <span class="merchantGroupLabel">实际用餐人数</span>
                        <span class="merchantGroupTxt">{{takeOutDetail.actualQty}}</span> 
                    </li> 
                    <li>
                        <span class="merchantGroupLabel">预算金额</span>
                        <span class="merchantGroupTxt">{{orderDetail.amount | price}}</span>
                    </li>
                    <li class="detail-mark">
                        <span class="merchantGroupLabel">备注</span>
                        <span class="merchantGroupTxt">{{takeOutDetail.remark}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode != 'ELE'" class="detail-mark">
                        <span class="merchantGroupLabel">发票抬头</span>
                        <span class="merchantGroupTxt">{{takeOutDetail.ext_restaurant_data | invoiceName}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode != 'ELE'" class="detail-mark">
                        <span class="merchantGroupLabel">发票税号</span>
                        <span class="merchantGroupTxt">{{takeOutDetail.ext_restaurant_data | invoiceNumber}}</span>
                    </li>
                    <li class="detail-phone" v-if="tracking.deliverymanInfo">
                        <span class="merchantGroupLabel">配送骑手</span>
                        <span class="detail-phone-people">{{tracking.deliverymanInfo ? (tracking.deliverymanInfo.name || '无') : '无'}}</span>
                        <span
                            v-if="tracking.deliverymanInfo && tracking.deliverymanInfo.phone"
                            class="detail-shop-phone"
                            @click="ihtml.telCall(tracking.deliverymanInfo.phone)"
                        >
                            <span class="border">
                                <Svgs color="var(--themeColor)" name="icondianhua-"></Svgs>
                            </span>
                            <span class="detail-phone-name">联系骑手</span>
                        </span>
                    </li>
                    <li v-if="tracking.deliverymanInfo">
                        <span class="merchantGroupLabel">配送状态</span>
                        {{getSatus(tracking.stateCode)}}
                    </li>
                    <li v-if="[50, 60].includes(takeOutDetail.status)">
                        <span class="merchantGroupLabel">骑手位置</span>
                        <div @click="goGps">
                            <img class="detail-rider" src="../../assets/img/address.png" />
                        </div>
                    </li>
                    <li class="detail-phone">
                        <span class="merchantGroupLabel">商家名称</span>
                        <span class="detail-phone-people">{{takeOutDetail.restaurant_name}}</span>
                        <span
                            class="detail-shop-phone"
                            v-if="takeOutDetail.restaurant_tel"
                            @click="ihtml.telCall(takeOutDetail.restaurant_tel)"
                        >
                            <span class="border">
                                <Svgs color="var(--themeColor)" name="icondianhua-"></Svgs>
                            </span>
                            <span class="detail-phone-name">联系商家</span>
                        </span>
                    </li>
                </ul>
            </div>
            <div class="merchantGroup">
                <div class="merchantGroupHead">订单状态</div>
                <ul class="merchantGroupList">
                    <li>
                        <span class="merchantGroupLabel">订单状态</span>
                        <span class="status">{{statusTxt}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode != 'ELE'">
                        <span v-if="orderDetail.sourceCode == 'DianPing'" class="merchantGroupLabel">第三方状态</span>
                        <span v-else class="merchantGroupLabel">小秘书状态</span>
                        <span class="status">{{takeOutDetail.statusTxt}}</span>
                    </li>
                    <li>
                        <span v-if="orderDetail.sourceCode == 'ELE'" class="merchantGroupLabel">饿了么订单号</span>
                        <span v-else-if="orderDetail.sourceCode == 'DianPing'" class="merchantGroupLabel">第三方订单号</span>
                        <span v-else class="merchantGroupLabel">小秘书订单号</span>
                        {{takeOutDetail.order_no}}
                    </li>
                    <li>
                        <span class="merchantGroupLabel">收货时间</span>
                        {{(takeOutDetail.zh_receiveTime || '').replace(/T/g, " ")}}
                    </li>
                    <li v-if="orderDetail.sourceCode == 'DianPing'">
                        <span class="merchantGroupLabel">第三方详情</span>
                        <span @click="openDdianPing" style="padding: 6px 30px;border: 1px solid #ccc;border-radius: 5px">查看</span>
                    </li>
                    <li v-if="orderDetail.sourceCode == 'ELE' && orderDetail.extOrderId" >
                        <span class="merchantGroupLabel">第三方详情</span>
                        <span @click="openELE" style="padding: 6px 30px;border: 1px solid #ccc;border-radius: 5px">查看</span>
                    </li>
                </ul>
            </div>

            <div class="merchantGroup">
                <div class="merchantGroupHead">实际用餐明细</div>
                <ul class="merchantMoList">
                    <li v-for="(item,index) in order" :key="'detail' + index">
                        <span class="detail-food-name">{{item.food_name}}</span>
                        <span
                            class="merchantMoPrice"
                        >{{(item.food_free*item.qty).toFixed(2) | price}}</span>
                        <span class="merchantMoNum">{{item.qty | count}}</span>
                    </li>
                    <li
                        class="merchantMoAllPrice"
                        v-for="(item, index) in (takeOutDetail.order_extras_obj || [])"
                        :key="index"
                    >
                        {{item.name}}
                        <span
                            class="merchantMoAllPriceNum"
                        >{{(item.price*item.quantity).toFixed(2) | price}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode == 'XiaoMiShu'" class="merchantMoAllPrice">
                        配送费
                        <span
                            class="merchantMoAllPriceNum"
                        >{{Number(takeOutDetail.shipping_free).toFixed(2)| price}}</span>
                    </li>
                    <li v-if="orderDetail.sourceCode == 'XiaoMiShu'" class="merchantMoAllPrice">
                        餐盒
                        <span
                            class="merchantMoAllPriceNum"
                        >{{Number(takeOutDetail.box_free).toFixed(2)| price}}</span>
                    </li>
                    <li v-if="!priceInfo" class="merchantMoAllPrice">
                        总金额
                        <span
                            class="merchantMoAllPriceNum"
                        >{{takeOutDetail.order_total_price | price}}</span>
                    </li>
                    <li v-if="priceInfo" class="merchantMoAllPrice">
                        其他费用
                        <span
                            class="merchantMoAllPriceNum"
                        >{{Number(priceInfo.order_amount - foodAmount).toFixed(2)| price}}</span>
                    </li>
                    <li v-if="priceInfo" class="merchantMoAllPrice">
                        订单优惠金额
                        <span
                            class="merchantMoAllPriceNum"
                        >{{ Number(priceInfo.order_amount - priceInfo.actual_total_amount).toFixed(2)| price}}</span>
                    </li>
                    <li v-if="priceInfo" class="merchantMoAllPrice">
                        订单总金额
                        <span
                            class="merchantMoAllPriceNum"
                        >{{priceInfo.order_amount | price}}</span>
                    </li>
                    <li v-if="priceInfo" class="merchantMoAllPrice">
                        实付金额
                        <span
                            class="merchantMoAllPriceNum"
                        >{{takeOutDetail.order_total_price | price}}</span>
                    </li>

                </ul>
            </div>
            <!-- <div class="merchantBtnRow">
                <div class="merchantGroupHead">水单</div>
                <viewer v-if="waterFilesList.length" :images="waterFilesList" class="imgContainer">
                    <template v-for="(list) in waterFilesList">
                        <div :key="list.url">
                            <img :src="list.url" alt />
                        </div>
                    </template>
                </viewer>
                <div class="nodata" v-else>暂无数据！</div>
            </div> -->
            <div class="merchantBtnRow">
                <div class="merchantGroupHead">发票</div>
                <viewer
                    v-if="invoiceFilesList.length"
                    :images="invoiceFilesList"
                    class="imgContainer"
                >
                    <template v-for="(list) in invoiceFilesList">
                        <div :key="list.url">
                            <div  @click="openPdf(list.url)" v-if="list.fileType == '.pdf'">
                                <i-icon style="width:.8rem;height:.8rem" class="icon-pdf" color="var(--themeColor)" name="icon-pdf" />
                            </div>
                            <div  @click="openOfd(list.url)" v-else-if="list.fileType == '.ofd'">
                                <img src="../../assets/img/ofd.png" style="width:.8rem;height:.8rem"/>                             </div>
                            <img v-else :src="list.url" alt/>
                        </div>
                    </template>
                </viewer>
                <div class="nodata" v-else>暂无数据！</div>
            </div>
        </div>
    </div>
</template>

<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import { UploadPlug } from "smart-filer";
import { SmartStorage } from "smart-core-util";
import mealDdianPing from '@/components/Meal/mealDdianPing.js'
import { Toast } from 'mint-ui';
const uploadInstance = new UploadPlug({
    host: process.env.VUE_APP_GATEWAY
});
export default {
    name: "orderDetail",
    mixins: [mealDdianPing],
    data() {
        return {
            takeOutDetail: {},
            address: {},
            order: [],
            tracking: {},
            statusTxt: "",
            waterFilesList: [],
            invoiceFilesList: [],
            style: {
                width: "0.3rem",
                height: "0.3rem",
                borderRadius: "50%",
                background: "rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            priceInfo: null,
        };
    },
    computed: {
        ...mapGetters(["orderDetail","mealRules"])
    },
    mounted() {
        this.getTakeoutDetail();
        this.getWaterFiles();
        this.getInvoiceFiles();
    },
    filters: {
        price: value => {
            if (value || value === 0) {
                return "¥" + value;
            }
        },
        count: value => {
            if (value) {
                return "X" + value;
            }
        },
        invoiceNumber: value => {
            if (value) {
                let data = JSON.parse(value)
                return data.invoiceNumber;
            }
        },
        invoiceName: value => {
            if (value) {
                let data = JSON.parse(value)
                return data.invoiceName;
            }
        }
    },
    methods: {
        openELE(){
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf("micromessenger") != -1;
            if (isWeixin) {            
                this.$router.push(`/common?aliURL=${encodeURIComponent(this.$route.query.aliURL)}&sessionId=${SmartStorage.get("sessionId")}&type=detailEle&placeOrder=true`)
            } else {
                window.location.href = this.$route.query.aliURL
            }
        },
        openDdianPing() {
            const params = {
                itemId: this.orderDetail.itemId
            };
            let _this = this;
            this.openTakeoutDianPing(params, () => {
                miceService.refreshDianPingOrder(params).then(res => {
                    if (res && res.success) {
                        _this.getTakeoutDetail();
                    }
                });
            });
        },
        openPdf(pdfUrl){
            this.iJsBridge.openPdfImg(pdfUrl)
            // window.open(pdfUrl)
        },
        openOfd(){
            Toast('暂不支持预览Ofd格式文件');
        },
        getTakeoutDetail() {
            let params = {
                itemId: this.orderDetail.itemId
            };
            miceService.getTakeoutDetail(params).then(res => {
                if (res && res.success) {
                    this.takeOutDetail = res.content.order || {};
                    this.priceInfo = res.content.priceInfo || null;
                    this.address = (res.content.order || {}).deliveryInfo || {};
                    this.order = res.content.orderDetail || [];
                    this.tracking = (res.content.tracking || {track: {}}).track || {};
                    this.statusTxt = res.content.statusTxt || "";
                    let foodAmount = 0;
                    for( let item in this.order){
                        foodAmount = foodAmount + this.order[item].qty * this.order[item].food_free
                    }
                    this.foodAmount = foodAmount
                    
                }
            });
        },
        getWaterFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        processId: this.orderDetail.proposalId,
                        relatedId: this.orderDetail.itemId,
                        relatedType: [
                            "settle",
                            "settle_reback",
                            "SupplierSettle"
                        ],
                        catalog: "水单"
                    }
                },
                callback: data => {
                    this.waterFilesList = data.content;
                }
            });
        },
        getInvoiceFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        processId: this.orderDetail.proposalId,
                        itemId: this.orderDetail.itemId,
                        relatedType: ["Invoice", "Invoice_reback"],
                        catalog: "发票"
                    }
                },
                callback: data => {
                    this.invoiceFilesList = data.content;
                }
            });
        },
        goGps() {
            this.$router.push({ path: `/takeoutGps?addressId=${this.takeOutDetail.address_id}`}) 
        },
        getSatus(code) {
            let result = "";
            switch (code) {
                case 1:
                    result = "运单生成";
                    break;
                case 2:
                    result = "配送商接单";
                    break;
                case 3:
                    result = "分配配送员";
                    break;
                case 4:
                    result = "配送员到店";
                    break;
                case 5:
                    result = "配送员取餐";
                    break;
                case 6:
                    result = "配送成功";
                    break;
                case 7:
                    result = "配送失败";
                    break;
                case 8:
                    result = "配送异常";
                    break;
                default:
                    result = "无";
                    break;
            }
            return result;
        }
    }
};
</script>

<style lang="scss">
@import "../MealOrderDetail/MealOrderDetail";
.takeOutEleDetail{
    .merchantBtnRow .icon-pdf{
        width: 0.8rem;
        height: 0.8rem;
        margin: 0.2rem 0.2rem 0 0;
        border-radius: 0.2rem;
    }
    .detail-mark {
        display: flex;

        span:nth-child(2) {
            width: calc(100% - 1rem);
            text-align: left;
        }
    }

    .detail-phone {
        position: relative;
    }

    .detail-shop-phone {
        position: absolute;
        top: 0px;
        color: #3788f1;
        right: 0px;
        display: flex;
        .border {
            width: 0.2rem !important;
            height: 0.2rem;
            border: 1px solid #3788f1;
            border-radius: 50%;
            text-align: center;
            display: flex;
            align-items: center;
        }
        .detail-phone-name {
            margin-left: 0.05rem;
        }
    }
    .detail-phone-people {
        width: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        text-align: left;
    }
    .detail-food-name {
        width: 1.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        text-align: left;
    }
    .detail-rider {
        width: 0.25rem;
    } 
}
</style>